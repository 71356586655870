import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import Logout from "./Logout";
import PasswordRecovery from "./PasswordRecovery";
import PasswordReset from "./PasswordReset";
import OTP from "./otp";

const AccountRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="otp" element={<OTP />} />

      {/* <Route path="passwordRecovery" element={<PasswordRecovery />} />
            <Route path="passwordReset" element={<PasswordReset />} /> */}
    </Routes>
  );
};

export default AccountRoutes;
