import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { Order } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiActivity, FiFileText } from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Receipt from "./Receipt";

interface Props {
  setTotalFilteredInvoices: React.Dispatch<React.SetStateAction<number>>;
}

const ListInvoices: React.FC<Props> = ({ setTotalFilteredInvoices }) => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [data, setData] = useState<PaginatedResult<Order>>(
    new PaginatedResult()
  );
  const [isFetchingPage, setIsFetchingPage] = useState(false);

  useEffect(() => {
    var url = window.location.href;
    queryStrings.set("size", "10");
    if (url.indexOf("?") !== -1) {
      // url has query string
      (async function () {
        setIsFetchingPage(true);
        var res = await httpService(ENDPIONTS.Invoices, queryStrings).getAll();
        setData(res.data);
        setTotalFilteredInvoices(
          (res.data as PaginatedResult<Order>).totalItems
        );
        setIsFetchingPage(false);
      })();
    } else {
      setData(new PaginatedResult());
    }
  }, [queryStrings]);

  const headers: ComplexHeader[] = [
    { key: "id", title: "Id" },
    { key: "tin", title: "TIN No." },
    { key: "tradeName", title: "Trade Name" },
    { key: "section", title: "section" },
    { key: "currency", title: "currency" },
    { key: "createdAt", title: "created At", format: "dateTime" },
    {
      key: "total",
      title: "Total",
      format: "currency",
      currency: (c: Order) => (c.currency === "SLSH" ? "SLSH" : "$"),
    },
    {
      key: "totalTaxable",
      title: "total Taxable",
      format: "currency",
      currency: (c: Order) => (c.currency === "SLSH" ? "SLSH" : "$"),
    },
    {
      key: "totalTax",
      title: "total Tax",
      format: "currency",
      currency: (c: Order) => (c.currency === "SLSH" ? "SLSH" : "$"),
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      click: (order: Order) => {
        showOrderReciptReceipt(order);
      },
      title: "Show Receipt",
      icon: FiFileText,
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const showOrderReciptReceipt = (order: Order) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <Receipt order={order} />,
    });
  };

  return (
    <div className="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
      <div className="skills layout-spacing ">
        <div className="widget-content widget-content-area">
          <Table
            data={data ?? []}
            headers={headers}
            actions={actions}
            onPageChange={handlePageChange}
            showCounter
            aboveTablePaination
            isFetchingPage={isFetchingPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListInvoices;
