import {
  InvoiceDashboard,
  Taxpayers,
  Users,
  Exchanges,
  ReportDashboard,
  TaxpayersActivities,
} from "@components";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Nav } from "./layout";
import { getCurrentUser } from "@api";

function App() {
  var currentUser = getCurrentUser();

  if (!currentUser) {
    window.location.assign("/auth/login");
    return <Navigate to={`/auth/login`} />;
  }

  return (
    <>
      {/* <Header /> */}
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <Nav />
        <div id="content" className="main-content">
          <Routes>
            <Route path="/" element={<InvoiceDashboard />} />
            <Route path="taxpayers" element={<Taxpayers />} />
            <Route path="users" element={<Users />} />
            <Route path="exchange" element={<Exchanges />} />
            <Route path="reports" element={<ReportDashboard />} />
            <Route
              path="reports/taxpayers/activities"
              element={<TaxpayersActivities />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
