import React, { useState } from "react";
import { MyContainer } from "@components";
import { useForm } from "react-hook-form";
import { GetTaxpayerViewModel, TaxpayerViewModel } from "@viewModels";
import { ENDPIONTS, httpService } from "@api";
import Swal from "sweetalert2";
import { Region } from "@models";

interface Props {
  taxpayer?: GetTaxpayerViewModel;
  callback?: () => void;
}

export const EditApiKey: React.FC<Props> = ({ taxpayer, callback }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);
    if (taxpayer) {
      // update
      const obj = {
        apiSecretKey: data?.apiSecretKey,
      };

      var res = await httpService(ENDPIONTS.UpdateApiSecretKey).update(
        taxpayer.id,
        obj
      );

      console.log("bj", obj);
      if (res?.status === 204) {
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "The record has been updated successfully.",
        });
        callback?.();
      }
    }
    setIsLoading(false);
  };

  return (
    <MyContainer
      title={taxpayer ? "Edit Taxpayers" : "Add New Taxpayer"}
      head={taxpayer ? "Edit Taxpayers" : "Add New Taxpayer"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-1">
          <div className="col">
            <div className="form-group">
              <label htmlFor="deviceId">API Secret Key*</label>
              <input
                type="text"
                className="form-control"
                id="apiSecretKey"
                name="apiSecretKey"
                ref={register({ required: false })}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col ">
            <input
              type="submit"
              value={
                isLoading
                  ? "loading...."
                  : taxpayer
                  ? "Edit Taxpayer"
                  : "Register Taxpayer"
              }
              style={{ padding: 10 }}
              className=" float-right btn btn-primary"
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </MyContainer>
  );
};
