import { Order } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import React, { useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { Helmet } from "react-helmet";
import { FiDownloadCloud, FiFileText } from "react-icons/fi";

const data = [
  { name: "name", description: "description" },
  { name: "name", description: "description" },
];

export const ExportReactCSV = ({ csvData }: any) => {
  const [isFetchingPage, setIsFetchingPage] = useState(false);

  const fileName = "Invoices" + new Date();

  return (
    <div className="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
      <Helmet>
        <title>MofTA</title>
        <link rel="stylesheet" type="text/css" href="/assets/Table.css" />
      </Helmet>
      <div className="skills layout-spacing ">
        <div>
          {/* <CSVLink data={csvData} filename={fileName}>Export</CSVLink> */}
        </div>
        <div className="widget-content widget-content-area">
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Receipts/Invoices</td>
                <td>Shows detailed list of receipts/invoices</td>
                <td>
                  {csvData.length > 0 ? (
                    <CSVLink
                      data={csvData}
                      filename={fileName}
                      className="btn white"
                      style={{ backgroundColor: "#5cb85c", color: "white" }}
                    >
                      Export
                    </CSVLink>
                  ) : (
                    <p
                      style={{
                        backgroundColor: "#d9534f",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Apply Filter First
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
