import { ENDPIONTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { Action, ComplexHeader, Table, TableDefaults } from "@shared";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  FiActivity,
  FiCheck,
  FiDelete,
  FiEdit,
  FiFileText,
  FiX,
} from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Taxpayer } from "@models";
import {
  GetTaxpayersActivitiesViewModel,
  GetTaxpayerViewModel,
  TaxpayerViewModel,
} from "@viewModels";
import { useForm } from "react-hook-form";

const TaxpayersActivities = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<{
    fromDate: string | null;
    toDate: string | null;
  }>({ fromDate: null, toDate: null });

  const [data, setData] = useState<GetTaxpayersActivitiesViewModel[]>([]);
  const [isFetchingPage, setIsFetchingPage] = useState(false);

  // const fetchDate = useCallback(async () => {
  //     var res = await httpService(ENDPIONTS.taxpayersActivities, queryStrings).getAll();
  //     setData(res.data);
  //     setIsFetchingPage(false);
  // }, [queryStrings]);

  useEffect(() => {
    var url = window.location.href;
    if (url.indexOf("?") !== -1) {
      // url has query string
      (async function () {
        setIsFetchingPage(true);
        var res = await httpService(
          ENDPIONTS.taxpayersActivities,
          queryStrings
        ).getAll();
        setData(res.data);
        setIsFetchingPage(false);
      })();
    } else {
      (document.querySelector("#fromDate") as HTMLInputElement).valueAsDate =
        new Date();
      (document.querySelector("#toDate") as HTMLInputElement).valueAsDate =
        new Date();

      var from = new Date(new Date().setHours(0, 0, 0)).toLocaleString();
      var to = new Date(new Date().setHours(23, 59, 59)).toLocaleString();

      queryStrings.set("fromDate", from);
      queryStrings.set("toDate", to);

      navigate({ search: `?${queryStrings.toString()}` });
    }
  }, [queryStrings]);

  // useEffect(() => {

  //     setIsFetchingPage(true);

  //     fetchDate();
  // }, [fetchDate]);

  const headers: ComplexHeader[] = [
    { key: "id", title: "Id" },
    { key: "tin", title: "TIN No." },
    { key: "taxpayerName", title: "Txpayer Name" },
    { key: "section", title: "section" },
    {
      key: "lastReportedDate",
      title: "Last Reported Date",
      // format: "date",
      renderer: {
        condition: (taxpayer: GetTaxpayerViewModel) =>
          new Date(taxpayer?.lastReportedDate ?? "").toLocaleDateString() ===
          new Date().toLocaleDateString(),
        ifTrue: "badge badge-success",
        ifFalse: "badge badge-danger",
      },
      formatter: (lastReportedDate: string) =>
        lastReportedDate
          ? new Date(lastReportedDate).toLocaleDateString()
          : "N/A",
    },
    { key: "dateDifference", title: "unreported days" },
    { key: "noOfOrders", title: "# Of Orders" },
    { key: "total", title: "total" },
    { key: "totalTaxable", title: "total Taxable" },
    { key: "totalTax", title: "total Tax" },
    { key: "currency", title: "currency" },
  ];

  const onSubmit = async (e: any) => {
    e.preventDefault();

    var from = dateRange.fromDate
      ? new Date(
          new Date(dateRange.fromDate).setHours(0, 0, 0)
        ).toLocaleString()
      : queryStrings.get("fromDate");
    var to = dateRange.toDate
      ? new Date(
          new Date(dateRange.toDate).setHours(23, 59, 59)
        ).toLocaleString()
      : queryStrings.get("toDate");

    queryStrings.set("fromDate", from!);
    queryStrings.set("toDate", to!);

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
      <div className="d-flex justify-content-around align-items-center">
        <div className="form-group row">
          <p className="w-25 ">From</p>
          <input
            type="date"
            name="fromDate"
            id="fromDate"
            className=" form-control w-75"
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, fromDate: e.target.value }))
            }
          />
        </div>
        <div className="form-group row">
          <p className="w-25">To</p>
          <input
            type="date"
            name="toDate"
            id="toDate"
            className=" form-control w-75"
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, toDate: e.target.value }))
            }
          />
        </div>
        <button
          type="button"
          onClick={onSubmit}
          className="btn mt-1"
          style={{ backgroundColor: "#5cb85c", color: "white" }}
        >
          <FiCheck size={20} /> Apply Filter
        </button>
      </div>

      <div className="skills layout-spacing ">
        <div className="widget-content widget-content-area">
          <Table
            data={data ?? []}
            headers={headers}
            showCounter
            isFetchingPage={isFetchingPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TaxpayersActivities;
