import { ENDPIONTS, PagingOptions } from "@api";
import { CurrentUser } from "@models";
import { UserViewModel } from "@viewModels";
import axios, { AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

// const BASE_URL = "https://localhost:7211/api/";
let BASE_URL = "";

if (process.env.NODE_ENV === "development") {
  BASE_URL = "https://localhost:7219/api/";
} else {
  BASE_URL = "https://mofta.slmof.org/api/";
}

export const httpService = <T>(
  endpoint: ENDPIONTS,
  options?: PagingOptions | URLSearchParams,
  userToken?: string
) => {
  const query = options ?? new PagingOptions();
  const formatedQuery =
    query instanceof PagingOptions ? query.format() : query.toString();

  let url = BASE_URL + endpoint;

  //requestInterceptors();
  responseInterceptors();
  const value = localStorage.getItem("token");
  const token = value
    ? JSON.parse(value)
    : userToken
    ? userToken
    : JSON.stringify("token");

  return {
    getAll: () =>
      axios.get(url + `?${formatedQuery}`, {
        headers: { Authorization: "Bearer " + token },
      }),
    getById: (id: any) =>
      axios.get(url + "/" + id + `?${formatedQuery}`, {
        headers: { Authorization: "Bearer " + token },
      }),
    post: (newRecord: T) =>
      axios.post(url, newRecord, {
        headers: { Authorization: "Bearer " + token },
      }),
    update: (id: number, updatedRecord: T) =>
      axios.put(url + "/" + id, updatedRecord, {
        headers: { Authorization: "Bearer " + token },
      }),
    delete: (id: number) =>
      axios.delete(url + "/" + id, {
        headers: { Authorization: "Bearer " + token },
      }),
  };
};

const requestInterceptors = () => {
  axios.interceptors.request.use((request: AxiosRequestConfig) => {
    // add auth header with jwt if account is logged in and request is to the api url
    //const token = "";
    const token = JSON.parse(
      localStorage.getItem("token") ?? JSON.stringify("token")
    );
    const isLoggedIn = token ? true : false; // check local storage and return true if token is there
    const isApiUrl = request?.url?.startsWith(BASE_URL);

    if (isLoggedIn && isApiUrl) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return request;
  });
};

const responseInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "The record has been added successfully.",
        });
      }
      return response;
    },
    (error) => {
      const token = localStorage.getItem("token");
      const isLoggedIn = token ? true : false; // check local storage and return true if token is there
      if (error?.response?.status === 401 && isLoggedIn) {
        //inform user that their session is ended with Swal alert.
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          text: "Invalid Login Attempt.",
        });
        logout();
      }
      if (error?.response?.status === 401) {
        //inform user that their session is ended with Swal alert.
        Swal.fire({
          icon: "error",
          showConfirmButton: false,
          text: "Invalid Login Attempt.",
        });
      }
      if (error?.response?.status === 400) {
        Swal.fire({
          text: `${error?.response?.data?.message ?? "Please try again."}`,
          showConfirmButton: false,
          icon: "error",
        });
      }
      if (error?.response?.status === 403) {
        Swal.fire({
          text: `${error?.response?.data?.message ?? "Please try again."}`,
          showConfirmButton: false,
          icon: "error",
        });
        logout();
      }
      return error;
    }
  );
};

export const logout = () => {
  localStorage.removeItem("token");
  window.location.assign("/auth/login");
  return;
  //  redirect them to loging page
  // with removing the token from local storage.
};

// export const getUserInfo = () => {
//   let decryptedData = new UserViewModel();

//   const currentUser = JSON.parse(
//     localStorage.getItem("token") ?? '""'
//   ) as string;
//   if (currentUser) {
//     // Decrypt
//     const bytes = crypto.AES.decrypt(currentUser, "MOFTIG_S_KEY_2024_5");
//     decryptedData = JSON.parse(
//       bytes.toString(crypto.enc.Utf8)
//     ) as UserViewModel;
//   }

//   return decryptedData;
// };

export const getCurrentUser = (): CurrentUser | null => {
  const jwt = localStorage.getItem("token");
  return !jwt ? null : jwtDecode(jwt);
};

export const decodeToken = (token: string): CurrentUser | null => {
  return !token ? null : jwtDecode(token);
};
