import { BaseModel } from "./baseModel";

export class Role extends BaseModel {
  id: number;
  name: string;
  description: string;
  permissions: string;
}

export enum RoleType {
  Admin = 1,
  Taxpayer = 2,
  Officer = 3,
  SuperAdmin = 4,
}
