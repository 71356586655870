import { ENDPIONTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { ListInvoices, MyContainer } from "@components";
import { Order } from "@models";
import { TableDefaults } from "@shared";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiCheck, FiX } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ExportReactCSV } from "./ExportReactCSV";

const ReportDashboard = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const { register, handleSubmit, errors } = useForm();

  const [invoicesCount, setInvoicesCount] = useState(0);
  const [totalFilteredInvoices, setTotalFilteredInvoices] = useState(0);

  const MySwal = withReactContent(Swal);

  const [data, setData] = useState<PaginatedResult<Order>>(
    new PaginatedResult()
  );
  const [isFetchingPage, setIsFetchingPage] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();

    var options = new PagingOptions();

    if (data?.fromDate && data?.toDate) {
      var from = new Date(
        new Date(data?.fromDate).setHours(0, 0, 0)
      ).toLocaleString();
      var to = new Date(
        new Date(data?.toDate).setHours(23, 59, 59)
      ).toLocaleString();
      options.filter<Order>((f) =>
        f.gte("createdAt", from).lte("createdAt", to)
      );
    }

    data?.tinNo && options.filter<Order>((f) => f.eq("tin", data?.tinNo));
    data?.tradeName &&
      options.filter<Order>((f) => f.like("tradeName", data?.tradeName));
    // data?.deviceId && options.filter<Order>(f => f.eq("deviceId", data?.deviceId))

    var additionalParams = "";

    if (data?.toUsd) additionalParams = `&toUsd=${data?.toUsd}`;
    if (data?.toSlsh) additionalParams = `&toSlsh=${data?.toSlsh}`;

    navigate({ search: `?${options.format()}${additionalParams}` });
  };

  var stylingObject = {
    input: {
      borderRadius: "3px",
      border: "1px solid gray",
    },
    button: {
      border: "1px solid gray",
      fontWeight: "bold",
    },
  };

  const toggleCurrency = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.checked;

    if (name === "toUsd" && value === true) {
      (document.querySelector("#toSlsh") as HTMLInputElement).checked = false;
    }
    if (name === "toSlsh" && value === true) {
      (document.querySelector("#toUsd") as HTMLInputElement).checked = false;
    }
  };

  //fetch InvoicesInTotals    useEffect(() => {

  useEffect(() => {
    var url = window.location.href;
    if (url.indexOf("?") !== -1) {
      // url has query string
      (async function () {
        setIsFetchingPage(true);
        var res = await httpService(ENDPIONTS.Invoices, queryStrings).getAll();
        setData(res.data);
        setTotalFilteredInvoices(
          (res.data as PaginatedResult<Order>).totalItems
        );
        setIsFetchingPage(false);
      })();
    } else {
      setData(new PaginatedResult());
    }
  }, [queryStrings]);
  console.log("dowload", data.items);
  return (
    <MyContainer title="Invoices">
      <div className="d-flex col-12 layout-top-spacing align-items-start">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="col-10 offset-1 alert alert-info d-flex flex-row"
        >
          <div className="col-5">
            <div className="d-flex align-items-center">
              <p className="w-25">From</p>
              <input
                type="date"
                name="fromDate"
                id="fromDate"
                ref={register()}
                className="w-50"
                style={stylingObject.input}
              />
            </div>
            <div className="d-flex align-items-center">
              <p className="w-25">To</p>
              <input
                type="date"
                name="toDate"
                id="toDate"
                ref={register()}
                className="w-50"
                style={stylingObject.input}
              />
            </div>
            <hr />
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center w-50">
                <p className="w-75">Sales</p>
                <input type="checkbox" />
              </div>
              <div className="d-flex align-items-center w-50">
                <p className="w-75">Refund</p>
                <input type="checkbox" />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="d-flex align-items-center w-50">
                <p className="w-75">To USD</p>
                <input
                  onChange={(e) => toggleCurrency(e)}
                  type="checkbox"
                  name="toUsd"
                  id="toUsd"
                  ref={register()}
                />
              </div>
              <div className="d-flex align-items-center w-50">
                <p className="w-75">To SLSH</p>
                <input
                  onChange={(e) => toggleCurrency(e)}
                  type="checkbox"
                  name="toSlsh"
                  id="toSlsh"
                  ref={register()}
                />
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="d-flex align-items-center">
              <p className="w-50">Tin</p>
              <input
                type="text"
                name="tinNo"
                ref={register()}
                style={stylingObject.input}
              />
            </div>
            <div className="d-flex align-items-center">
              <p className="w-50">Trade Name</p>
              <input
                type="text"
                name="tradeName"
                ref={register()}
                style={stylingObject.input}
              />
            </div>
            <div className="d-flex align-items-center">
              <p className="w-50">Device ID</p>
              <input
                type="text"
                name="deviceId"
                ref={register()}
                style={stylingObject.input}
              />
            </div>
            <div className="d-flex align-items-center">
              <p className="w-75" style={{ minWidth: "50%" }}>
                Account from-to
              </p>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder=">="
                  style={stylingObject.input}
                  className="w-25 mr-5"
                />
                <input
                  type="text"
                  placeholder="<"
                  style={stylingObject.input}
                  className="w-25"
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="w-50" style={{ minWidth: "50%" }}>
                Counter from-to
              </p>
              <div className="d-flex">
                <input
                  type="text"
                  placeholder=">="
                  style={stylingObject.input}
                  className="w-25 mr-5"
                />
                <input
                  type="text"
                  placeholder="<"
                  style={stylingObject.input}
                  className="w-25"
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div>
              <button
                type="reset"
                onClick={() => {
                  navigate({ search: `` });
                  setTotalFilteredInvoices(0);
                }}
                className="btn mt-1"
                style={{ backgroundColor: "#d9534f", color: "white" }}
              >
                <FiX size={20} /> Reset Filter
              </button>
              <button
                type="submit"
                className="btn mt-1"
                style={{ backgroundColor: "#5cb85c", color: "white" }}
              >
                <FiCheck size={20} /> Apply Filter
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Tabs */}

      <ExportReactCSV csvData={data.items} />
    </MyContainer>
  );
};

export default ReportDashboard;
