import { BaseModel } from "@models";
import { Role } from "./role";

export class User extends BaseModel {
  fullName: string;
  userName: string;
  email: string;
  password: string;
  roleId: number;
  role: Role;
  phoneNumber: string;
  region: Region;
}

export class CurrentUser {
  fullName: string;
  userName: string;
  email: string;
  password: string;
  roleId: number;
  role: string;
  tokens?: string;
  smsToken?: number;
  phoneNumber?: string;
}

export enum Region {
  Hargeisa,
  Gabiley,
  Wajaale,
  Boorama,
  Burco,
  Berbera,
}
