export enum Operation {
    eq = 'eq',
    // neq = 'neq',
    like = 'like',
    gt = 'gt',
    gte = 'gte',
    lt = 'lt',
    lte = 'lte',
    // any = "any",
    // in = "in",
}
