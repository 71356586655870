import React, { useState } from "react";
import { MyContainer } from "@components";
import { useForm } from "react-hook-form";
import { GetTaxpayerViewModel, TaxpayerViewModel } from "@viewModels";
import { ENDPIONTS, httpService } from "@api";
import Swal from "sweetalert2";
import { Region } from "@models";

interface Props {
  taxpayer?: GetTaxpayerViewModel;
  callback?: () => void;
}

export const AddTaxpayer: React.FC<Props> = ({ taxpayer, callback }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);
    if (taxpayer) {
      // update
      const obj = {
        tinNo: data?.tin,
        tradeName: data?.userName,
        section: data?.section,
        currency: data?.currency,
        region: data?.region,
      };

      var res = await httpService(ENDPIONTS.UpdateTaxpayer).update(
        taxpayer.id,
        obj
      );

      if (res?.status === 204) {
        //  taxpayer = new TaxpayerViewModel();
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "The record has been updated successfully.",
        });
        callback?.();
      }
    } else {
      // save
      const obj = {
        tinNo: data?.tin,
        userName: data?.userName,
        section: data?.section,
        currency: data?.currency,
        region: data?.region,
        apiSecretKey: data?.apiSecretKey,
        roleId: 2,
      };

      var result = await httpService(ENDPIONTS.RegisterTaxpayer).post(obj);
      if (result?.status === 201) {
        // taxpayer = new TaxpayerViewModel();
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "The record has been updated Created.",
        });

        callback?.();
        e.target.reset();
      }
    }
    setIsLoading(false);
  };

  return (
    <MyContainer
      title={taxpayer ? "Edit Taxpayers" : "Add New Taxpayer"}
      head={taxpayer ? "Edit Taxpayers" : "Add New Taxpayer"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-1" style={{ marginTop: 60 }}>
          <div className="col">
            <div className="form-group">
              <label htmlFor="tin">TIN Number*</label>
              <input
                defaultValue={taxpayer?.tin}
                type="text"
                className="form-control"
                id="tin"
                name="tin"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.tin && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <div className="form-group">
              <label htmlFor="userName">Taxpayer Name*</label>
              <input
                defaultValue={taxpayer?.taxpayerName}
                type="text"
                className="form-control"
                id="userName"
                name="userName"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.taxpayerName && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        {!taxpayer && (
          <div className="row mb-1">
            <div className="col">
              <div className="form-group">
                <label htmlFor="deviceId">API Secret Key*</label>
                <input
                  type="text"
                  className="form-control"
                  id="apiSecretKey"
                  name="apiSecretKey"
                  ref={register({ required: false })}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row mb-1">
          <div className="col">
            <div className="form-group">
              <label htmlFor="section">Section*</label>
              <select
                className="form-control"
                defaultValue={taxpayer?.section}
                id="section"
                name="section"
                ref={register({ required: true })}
              >
                <option></option>
                <option value="Restaurant">Restaurant</option>
                <option value="Hotel">Hotel</option>
                <option value="SuperMarket">SuperMarket</option>
              </select>
              <span className="text-danger">
                {errors.section && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="region">Taxpayer Region*</label>
              <select
                className="form-control"
                id="region"
                name="region"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(Region)
                  .filter((r) => isNaN(Number(r))) // Filter out numeric keys (if any)
                  .map((r, i) => {
                    return (
                      <option
                        selected={taxpayer?.region.toString() === r}
                        key={i}
                        value={r}
                      >
                        {r}
                      </option>
                    );
                  })}
              </select>
              <span className="text-danger">
                {errors.region && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="currency">Currency*</label>
              <select
                className="form-control"
                id="currency"
                defaultValue={taxpayer?.currency}
                name="currency"
                ref={register({ required: true })}
              >
                <option></option>
                <option value="USD">USD</option>
                <option value="SLSH">SLSH</option>
              </select>
              <span className="text-danger">
                {errors.currency && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col ">
            <input
              type="submit"
              value={
                isLoading
                  ? "loading...."
                  : taxpayer
                  ? "Edit Taxpayer"
                  : "Register Taxpayer"
              }
              style={{ padding: 10 }}
              className=" float-right btn btn-primary"
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </MyContainer>
  );
};
