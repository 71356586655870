import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  children: React.ReactNode;
  title?: string;
  head?: string;
  size?: string;
}

const MyContainer: React.FC<Props> = ({ children, title, head, size }) => {
  return (
    <div className={size || "col-12"}>
      <Helmet>
        <title>{title || "MofTA"}</title>
      </Helmet>
      <div className="page-header">
        <div className="page-title">
          <h3>{head || ""}</h3>
        </div>
      </div>
      <div className="layout-spacing">{children}</div>
    </div>
  );
};

export default MyContainer;
