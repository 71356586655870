import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { Order } from "@models";
import { ComplexHeader, Table } from "@shared";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GroupedInvoicesPost, GroupedInvoicesViewModel } from "@viewModels";

interface Props {
  setTotalFilteredInvoices: React.Dispatch<React.SetStateAction<number>>;
  currentWindow: "Totals" | "Invoices" | "Daily" | "Weekly" | "Monthly";
}

const GroupedInvoices: React.FC<Props> = ({
  setTotalFilteredInvoices,
  currentWindow,
}) => {
  const [queryStrings] = useSearchParams();

  const [data, setData] = useState<PaginatedResult<GroupedInvoicesViewModel>>(
    new PaginatedResult()
  );
  const [isFetchingPage, setIsFetchingPage] = useState(false);

  useEffect(() => {
    var url = window.location.href;
    if (url.indexOf("?") != -1) {
      // url has query string
      (async function () {
        setIsFetchingPage(true);
        var grouping: number = 0;

        if (currentWindow === "Daily") grouping = 0;
        if (currentWindow === "Weekly") grouping = 1;
        if (currentWindow === "Monthly") grouping = 2;

        var params: GroupedInvoicesPost = {
          groupType: grouping,
          from: queryStrings.get("createdAt.gte"),
          to: queryStrings.get("createdAt.lte"),
          toUsd: queryStrings.get("toUsd")
            ? (queryStrings.get("toUsd") as unknown as boolean)
            : false,
          toSlsh: queryStrings.get("toSlsh")
            ? (queryStrings.get("toSlsh") as unknown as boolean)
            : false,
        };

        var res = await httpService(ENDPIONTS.GroupedInvoices).post(params);

        setData(res.data);
        setTotalFilteredInvoices(
          (res.data as PaginatedResult<Order>).totalItems
        );
        setIsFetchingPage(false);
      })();
    } else {
      setData(new PaginatedResult());
    }
  }, [queryStrings, currentWindow]);

  const headers: ComplexHeader[] = [
    { key: "groupedBy", title: "grouped By" },
    { key: "totalInvoices", title: "total Invoices" },
    {
      key: "total",
      title: "Total",
      format: "currency",
      currency: (c: GroupedInvoicesViewModel) =>
        c?.currency === "SLSH" ? "SLSH" : "$",
    },
    {
      key: "totalTax",
      title: "total Tax",
      format: "currency",
      currency: (c: GroupedInvoicesViewModel) =>
        c?.currency === "SLSH" ? "SLSH" : "$",
    },
    {
      key: "totalSales",
      title: "total Taxable",
      format: "currency",
      currency: (c: GroupedInvoicesViewModel) =>
        c?.currency === "SLSH" ? "SLSH" : "$",
    },
  ];

  return (
    <div className="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
      <div className="skills layout-spacing ">
        <div className="widget-content widget-content-area">
          <Table
            data={data ?? []}
            headers={headers}
            hidePagination
            showCounter
            isFetchingPage={isFetchingPage}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupedInvoices;
