export enum ENDPIONTS {
  Invoices = "order",
  CountInvoices = "order/count",
  OrderDetails = "order/orderDetails",
  GroupedInvoices = "order/groupedOrders",
  Totals = "order/totals",
  taxpayers = "user/taxpayers",
  taxpayersActivities = "user/taxpayersActivities",
  login = "login",
  user = "user",
  registerUser = "user/registerUser",
  recovery = "user/sendEmail",
  userChnagePassword = "user/changePassword",
  roles = "roles",
  exchange = "exchange",
  OTP = "login/validateOTP",
  ReSendSMS = "login/sendSMS",
  RegisterTaxpayer = "user",
  UpdateTaxpayer = "user/UpdateTaxpayer",
  UpdateApiSecretKey = "user/UpdateApiSecretKey",
}
