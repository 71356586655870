import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  FiUsers,
  FiAlertOctagon,
  FiHome,
  FiTwitch,
  FiX,
  FiFile,
  FiCalendar,
  FiFileText,
  FiFilm,
} from "react-icons/fi";
import { FaHandHoldingUsd } from "react-icons/fa";
import { HiCheck, HiLogout, HiUser } from "react-icons/hi";
import { getCurrentUser } from "@api";
import { RoleType } from "@models";

export const Nav = () => {
  useEffect(() => {}, []);

  const currentUser = getCurrentUser();

  console.log("currentUser", currentUser);

  return (
    <>
      <div className="topbar-nav header navbar mt-3" role="banner">
        <nav id="topbar">
          <ul className="navbar-nav theme-brand flex-row  text-center">
            <li className="nav-item theme-logo">
              <a href="index.html">
                <img
                  src="assets/img/90x90.jpg"
                  className="navbar-logo"
                  alt="logo"
                />
              </a>
            </li>
            <li className="nav-item theme-text">
              <a href="index.html" className="nav-link">
                {" "}
                CORK{" "}
              </a>
            </li>
          </ul>

          <ul className="list-unstyled menu-categories" id="topAccordion">
            <li className="menu single-menu active">
              <a
                href="#dashboard"
                data-toggle="collapse"
                aria-expanded="true"
                className="dropdown-toggle autodroprown"
              >
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                  </svg>
                  <span>Dashboard</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </a>
            </li>

            <li className="menu single-menu">
              <a
                href="#app"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  {" "}
                  <FiCalendar size="20" />
                  <span>Today</span>
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="app"
                data-parent="#topAccordion"
              >
                <li>
                  <NavLink to="/"> Todays Receipts </NavLink>
                </li>
                {/* <li>
                                    <a href="/" onClick={(e) => { customerLookupHandler(e) }}> Customer Lookup </a>
                                </li> */}
              </ul>
            </li>

            <li className="menu single-menu">
              <a
                href="#app"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  {" "}
                  <FiFileText size="20" />
                  <span>Data</span>
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="app"
                data-parent="#topAccordion"
              >
                <li>
                  <NavLink to="/"> Receipts</NavLink>
                </li>
                <li>
                  <NavLink to="#">Daily Totals</NavLink>
                </li>
                <li>
                  <NavLink to="#">Weekly Totals</NavLink>
                </li>
                <li>
                  <NavLink to="#">Monthly Totals</NavLink>
                </li>
              </ul>
            </li>

            <li className="menu single-menu">
              <a
                href="#app"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  {" "}
                  <FiFile size="20" />
                  <span>Reports</span>
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="app"
                data-parent="#topAccordion"
              >
                <li>
                  <NavLink to="reports"> Report</NavLink>
                </li>
                <li>
                  <NavLink to="reports/taxpayers/activities">
                    {" "}
                    Taxpayer Activities
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* <li className="menu single-menu">
                            <NavLink to="bills/nonworking" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                    <FiAlertOctagon />
                                    <span>Nonworking Meter Bills</span>
                                </div>
                            </NavLink>
                        </li>

                        <li className="menu single-menu">
                            <NavLink to="payments" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                    <HiCheck />
                                    <span>Payments</span>
                                </div>
                            </NavLink>
                        </li>

                        <li className="menu single-menu">
                            <NavLink to="bills/fixed-bills" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                                <div className="">
                                    <FiTwitch />
                                    <span>Fixed Bills</span>
                                </div>
                            </NavLink>
                        </li> */}

            <li
              className="menu single-menu "
              // hidden={
              //   currentUser
              //     ? +currentUser?.roleId !== RoleType.SuperAdmin
              //     : true
              // }
            >
              <a
                href="#app"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  {" "}
                  <FiX size="20" />
                  <span>Settings</span>
                </div>
              </a>
              <ul
                className="collapse submenu list-unstyled"
                id="app"
                data-parent="#topAccordion"
              >
                <li>
                  <NavLink to="taxpayers">Taxpayers</NavLink>
                </li>
                <li>
                  <NavLink to="users">Users</NavLink>
                </li>
                <li>
                  <NavLink to="exchange">Exchanges</NavLink>
                </li>
              </ul>
            </li>

            <li className="menu single-menu">
              <NavLink to="auth/logout" className="dropdown-toggle">
                <div className="">
                  <HiLogout />
                  <span>Logout</span>
                </div>
              </NavLink>
            </li>

            <li className="menu single-menu">
              <NavLink to="#" className="dropdown-toggle">
                <div className="">
                  <HiUser />
                  <span>
                    Logged in as {currentUser?.role}(
                    {currentUser?.fullName ?? ""})
                  </span>
                </div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
