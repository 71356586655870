import { Exchange } from "@models";
import { MyContainer } from "@components";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ENDPIONTS, httpService } from "@api";
import { ExchangeViewModel } from "@viewModels";
import Swal from "sweetalert2";

interface Props {
  exchange?: Exchange;
  callback?: () => void;
}

const EditExchange: React.FC<Props> = ({ exchange, callback }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);
    if (exchange) {
      // update
      const obj: ExchangeViewModel = {
        name: data?.name,
        rate: data?.rate,
      };
      var res = await httpService(ENDPIONTS.exchange).update(exchange.id, obj);
      if (res?.status === 200) {
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "The record has been updated successfully.",
        });
        callback?.();
      }
    }
  };

  return (
    <MyContainer title="Edit Exchange" head="Edit Exchange">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-1" style={{ marginTop: 60 }}>
          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Exchange Name*</label>
              <input
                defaultValue={exchange?.name}
                type="text"
                className="form-control"
                id="name"
                name="name"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.name && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <div className="form-group">
              <label htmlFor="rate">Exchange Rate*</label>
              <input
                defaultValue={exchange?.rate}
                type="text"
                className="form-control"
                id="rate"
                name="rate"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.rate && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col ">
            <input
              type="submit"
              value={
                isLoading
                  ? "loading...."
                  : exchange
                  ? "Edit Exchange"
                  : "Register Exchange"
              }
              style={{ padding: 10 }}
              className=" float-right btn btn-primary"
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </MyContainer>
  );
};

export default EditExchange;
