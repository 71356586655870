import { ENDPIONTS, httpService } from "@api";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

type Param = {
  state: { userName: string | undefined };
};

const OTP = () => {
  const { register, handleSubmit, errors } = useForm();
  const navigate = useNavigate();
  const [isResending, setIsResending] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  const { state } = useLocation() as Param;

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();

    if (!state || !state.userName) return;

    setIsSubmitting(true);

    const payload = {
      userName: state.userName ?? "",
      sms: Number(data.sms),
    };

    try {
      var res = await httpService(ENDPIONTS.OTP).post(payload);

      if (res?.status === 200 && res.data.token) {
        localStorage.setItem("token", JSON.stringify(res.data.token));
        e.target.reset();
        navigate("/");
      }
    } catch (e) {
      console.error("Error submitting form: ", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onReSend = async () => {
    if (!state || !state.userName) return;

    setIsResending(true);

    try {
      var res = await httpService(ENDPIONTS.ReSendSMS).post({
        userName: state.userName,
      });

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          showConfirmButton: false,
          text: "Sent 😊",
        });
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        text: "SMS Didn't sent 😒",
      });
    } finally {
      setIsResending(false);
    }
  };

  if (!state || !state.userName) {
    window.location.assign("/auth/login");
    return <Navigate to={`/auth/login`} />;
  }

  return (
    <div className="form">
      <Helmet>
        <title>OTP</title>
        {/* Start Login page styles */}
        <link
          href="/assets/css/authentication/form-1.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/forms/theme-checkbox-radio.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/assets/css/forms/switches.css"
        />
        {/* End Login page styles */}
      </Helmet>
      <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <h1>Enter SMS Code</h1>
                {/* <p className="signup-link">New Here? <a href="auth_register.html">Create an account</a></p> */}
                <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <input
                        id="sms"
                        name="sms"
                        type="number"
                        ref={register({ required: true })}
                        onChange={() => setErrMsg(null)}
                        className="form-control"
                        placeholder="Code"
                      />
                      <span className="text-danger">
                        {errors.title && <span>This field is required</span>}
                      </span>
                    </div>
                    <div className="field-wrapper d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={() => onReSend()}
                        className="btn btn-secondary  mr-3"
                        disabled={isResending}
                      >
                        {isResending ? "Sending..." : "Re-Send"}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-dark"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Verifying..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
                {errMsg && (
                  <div className="row mt-3">
                    <p className="text-danger font-weight-bold"> {errMsg} </p>
                  </div>
                )}
                <p className="terms-conditions">
                  © 2022 All Rights Reserved. Developed by IT Department at MOF
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP;
