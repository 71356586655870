import { ENDPIONTS, httpService } from '@api';
import { Order, OrderDetail } from '@models'
import { url } from 'inspector'
import React, { useEffect, useState } from 'react'

import './receipt.css'

interface Props {
    order: Order;
}

const Receipt: React.FC<Props> = ({ order }) => {

    const [orderDetails, setOrderDetails] = useState<OrderDetail[]>();

    useEffect(() => {
        (async function () {
            var res = await httpService(ENDPIONTS.OrderDetails).getById(`${order.id}/${order.target}`);
            setOrderDetails(res.data);
        })();
    }, [order])


    return (
        <>
            <h6>Order Receipt</h6>
            <hr />
            <div className="receiptpage">
                <div className="bold center h6">
                    {(order?.tradeName ?? "Name of the shop" as string).toUpperCase()}
                </div>
                <br />
                <div className="row segment">
                    <div className="col-6 bold left">TIN: </div>
                    <div className="col-6 bold right">{order?.tin ?? "00000000"} </div>
                </div>
                <br />
                <div className="row segment">
                    <div className="col-6 left">Date and Time</div>
                    <div className="col-6 right">{new Date(order?.createdAt).toLocaleString()}</div>
                </div>

                {/* <div className="center segment">NAME THE POINT OF SALE</div>
                <div className="center segment">Street and No</div>
                <div className="center segment">Contact info: 93874298437 / 938274</div> */}
                <hr />
                {/* <div className="row segment">
                    <div className="col-6 bold left">Seller: </div>
                    <div className="col-6 bold right">1 Operator01 </div>
                </div>
                <div className="row segment">
                    <div className="col-6 bold left">ISN: </div>
                    <div className="col-6 bold right">S08493943848 </div>
                </div> */}

                <br />
                <div className="segment pt-3 pb-3" style={{ border: "1px solid black" }}>
                    <div className="center segment">--- GST RECEIPT ---</div>
                </div>
                <br />
                <br />
                {
                    orderDetails?.map((d: OrderDetail, i: number) => {
                        return (
                            <div className="row segment mt-1 mb-1">
                                <div className="col-7 left">
                                    {d?.orderName} <br />
                                    Qty {d?.quantity} x {d?.price?.toCurrency(order?.currency === "SLSH" ? "SLSH" : "$")}
                                </div>
                                <div className="col-5 right">*{d?.total?.toCurrency(order?.currency === "SLSH" ? "SLSH" : "$")} </div>
                            </div>
                        )
                    })
                }
                {/* <div className="row segment mt-1 mb-1">
                    <div className="col-6 left">Item 01  Qty 1 x 10</div>
                    <div className="col-6 right">*10.00 </div>
                </div> */}
                <br />
                <hr className='hr' style={{ border: "1px dashed black" }} />
                <div className="row segment">
                    <div className="col-6 left">Taxable</div>
                    <div className="col-6 right">*{order?.totalTaxable?.toCurrency(order?.currency === "SLSH" ? "SLSH" : "$")} </div>
                </div>
                <div className="row segment">
                    <div className="col-6 left">GST 5%</div>
                    <div className="col-6 right">*{order?.totalTax?.toCurrency(order?.currency === "SLSH" ? "SLSH" : "$")}  </div>
                </div>
                <hr className='hr' style={{ border: "1px dashed black" }} />
                <br />

                <div className="row segment">
                    <div className="col-6 bold left">TOTAL AMOUNT</div>
                    <div className="col-6 bold right">*{order?.total?.toCurrency(order?.currency === "SLSH" ? "SLSH" : "$")}  </div>
                </div>
                <br />
                <br />


                {/* <br />
                <br />
                <div className="bold center">
                    THANK YOU AND SEE YOU SOON
                </div> */}
            </div>
        </>
    )
}

export default Receipt