import { ENDPIONTS, getCurrentUser, httpService, PaginatedResult } from "@api";
import { Region, RoleType, User } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import React, { useCallback, useEffect, useState } from "react";
import { FiDelete, FiEdit } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddUser from "./AddUser";
import ChnagePasword from "./ChnagePasword";

const Users = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const currentUser = getCurrentUser();

  const [data, setData] = useState<PaginatedResult<User>>(
    new PaginatedResult()
  );
  const [isFetchingPage, setIsFetchingPage] = useState(false);

  const fetchDate = useCallback(async () => {
    var res = await httpService(ENDPIONTS.user, queryStrings).getAll();
    console.log("res", res);
    setData(res.data);
    setIsFetchingPage(false);
  }, [queryStrings]);

  useEffect(() => {
    setIsFetchingPage(true);

    fetchDate();
  }, [fetchDate]);

  const headers: ComplexHeader[] = [
    { key: "id", title: "Id" },
    { key: "fullName", title: "Full Name" },
    { key: "userName", title: "User Name" },
    { key: "email", title: "Email" },
    { key: "region", title: "Region" },
    { key: "role?.name", title: "Role" },
  ];

  const actions: Action[] = [
    {
      key: "2",
      hide: (user: User) =>
        currentUser ? +currentUser?.roleId !== RoleType.SuperAdmin : true,
      click: (user: User) => {
        changePassword(user);
      },
      title: "Change Password",
      color: "info",
    },
    {
      key: "1",
      hide: (user: User) =>
        currentUser ? +currentUser?.roleId !== RoleType.SuperAdmin : true,
      click: (user: User) => {
        showDocDetails(user);
      },
      title: "Update",
      color: "success",
    },
  ];

  const changePassword = (user: User) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <ChnagePasword user={user} callback={fetchDate} />,
    });
  };

  const showDocDetails = (user: User) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <AddUser user={user} callback={fetchDate} />,
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const Add = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <AddUser callback={fetchDate} />,
    });
  };

  return (
    <div className="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
      <div className="skills layout-spacing ">
        <div className="text-right">
          <button
            type="submit"
            className="btn btn-primary "
            onClick={Add}
            hidden={
              currentUser ? +currentUser?.roleId !== RoleType.SuperAdmin : true
            }
            style={{ padding: 12, margin: 6, marginLeft: 4 }}
          >
            Add New User
          </button>
        </div>
        <div className="widget-content widget-content-area">
          <Table
            data={
              data.items.map((o) => ({ ...o, region: Region[o.region] })) ?? []
            }
            headers={headers}
            actions={
              currentUser && +currentUser?.roleId !== RoleType.SuperAdmin
                ? []
                : actions
            }
            onPageChange={handlePageChange}
            showCounter
            aboveTablePaination
            isFetchingPage={isFetchingPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
