import { ENDPIONTS, httpService } from '@api';
import { User } from '@models';
import { ChangePasswordViewModel } from '@viewModels';
import { MyContainer } from '@components'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

interface Props {
    user?: User;
    callback?: () => void
}

const ChnagePasword: React.FC<Props> = ({ user, callback }) => {
    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any, e: any) => {
        console.log("data sending", data);
        if (data.password === data.comfirmpassword) {
            setIsLoading(true);
            if (user) { // update
                const obj: ChangePasswordViewModel = {
                    id: user.id,
                    prevPassword: data.prevPassword,
                    newPassword: data.password,
                }
                var res = await httpService(ENDPIONTS.userChnagePassword).post(obj);
                if (res?.status === 200) {
                    //  taxpayer = new TaxpayerViewModel();
                    Swal.fire({
                        icon: "success",
                        showConfirmButton: false,
                        text: "The Password has been changed successfully.",
                    });
                    callback?.();
                }
                setIsLoading(false);
            } else {
                Swal.fire({
                    icon: "error",
                    showConfirmButton: false,
                    text: "Password & Comfirm Password must match",
                });
            }
        }
    }
    return (
        <MyContainer title={user ? 'Change  Password' : 'Change  Password'} head={user ? 'Change  Password' : 'Change  Password'}  >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-1" style={{ marginTop: 60 }}>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="prevPassword">Old Password*</label>
                            <input
                                type="password"
                                className="form-control"
                                id="prevPassword"
                                name="prevPassword"
                                ref={register({ required: true })}
                            />
                            <span className="text-danger">
                                {errors.prevPassword && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>

                </div>


                <div className="row mb-1">
                    <div className="col">
                        <div className="form-group" >
                            <label htmlFor="password"> Password*</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                ref={register({ required: true })}
                            />
                            <span className="text-danger">
                                {errors.password && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                    <div className="col">

                        <div className="form-group">
                            <label htmlFor="comfirmpassword">Comfirm Password*</label>
                            <input
                                type="password"
                                className="form-control"
                                id="comfirmpassword"
                                name="comfirmpassword"
                                ref={register({ required: true })}
                            />
                            <span className="text-danger">
                                {errors.comfirmpassword && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col ">
                        <input type="submit" value={isLoading ? "loading...." : user ? "Change Passwor" : "Change Password"} style={{ padding: 10 }} className=' float-right btn btn-primary' disabled={isLoading} />
                    </div>
                </div>
            </form>
        </MyContainer>
    )
}

export default ChnagePasword