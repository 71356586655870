import { ENDPIONTS, httpService } from "@api";
import { Region, User } from "@models";
import { RolesViewModel, UserViewModel } from "@viewModels";
import { MyContainer } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

interface Props {
  user?: User;
  callback?: () => void;
}

const AddUser: React.FC<Props> = ({ user, callback }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState<RolesViewModel[]>([]);

  const fetchDate = useCallback(async () => {
    var res = await httpService(ENDPIONTS.roles).getAll();
    setRoles(res.data);
  }, []);

  useEffect(() => {
    fetchDate();
  }, [fetchDate]);

  const onSubmit = async (data: any, e: any) => {
    console.log("data sending", data);
    if (data.password === data.comfirmpassword) {
      setIsLoading(true);
      if (user) {
        // update
        const obj: UserViewModel = {
          fullName: data?.fullName,
          userName: data?.userName,
          email: data?.email,
          password: data?.password,
          roleId: data?.roleId,
          phoneNumber: data?.phoneNumber,
          region: data?.region,
        };

        console.log("obj", obj);
        var res = await httpService(ENDPIONTS.user).update(user.id, obj);
        if (res?.status === 200) {
          //  taxpayer = new TaxpayerViewModel();
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            text: "The record has been updated successfully.",
          });
          callback?.();
        }
      } else {
        // save
        const obj: UserViewModel = {
          fullName: data?.fullName,
          userName: data?.userName,
          email: data?.email,
          password: data?.password,
          roleId: data?.roleId,
          phoneNumber: data?.phoneNumber,
          region: data?.region,
        };

        console.log("sending obj", obj);

        var result = await httpService(ENDPIONTS.registerUser).post(obj);
        if (result?.status === 200) {
          // taxpayer = new TaxpayerViewModel();
          Swal.fire({
            icon: "success",
            showConfirmButton: false,
            text: "The record has been updated Created.",
          });
          callback?.();
          e.target.reset();
        }
      }
      setIsLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        text: "Password & Comfirm Password must match",
      });
    }
  };

  return (
    <MyContainer
      title={user ? "Edit User" : "Add New User"}
      head={user ? "Edit User" : "Add New User"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-1" style={{ marginTop: 60 }}>
          <div className="col">
            <div className="form-group">
              <label htmlFor="fullName">Full Name*</label>
              <input
                defaultValue={user?.fullName}
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.fullName && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <div className="form-group">
              <label htmlFor="userName">User Name*</label>
              <input
                defaultValue={user?.userName}
                type="text"
                className="form-control"
                id="userName"
                name="userName"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.userName && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="userName">Phone Number*</label>
              <input
                defaultValue={user?.phoneNumber}
                type="text"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.userName && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <div className="form-group">
              <label htmlFor="email">Email Address*</label>
              <input
                defaultValue={user?.email}
                type="email"
                className="form-control"
                id="email"
                name="email"
                ref={register({ required: true })}
              />
              <span className="text-danger">
                {errors.email && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="roleId">User Role*</label>
              <select
                className="form-control"
                id="roleId"
                name="roleId"
                ref={register({ required: true })}
              >
                <option></option>
                {roles?.map((o, i) => {
                  return (
                    <option
                      selected={user?.role?.name === o.name}
                      key={i}
                      value={o.id}
                    >
                      {" "}
                      {o.name}
                    </option>
                  );
                })}
              </select>
              <span className="text-danger">
                {errors.roleId && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="region">User Region*</label>
              <select
                className="form-control"
                id="region"
                name="region"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(Region)
                  .filter((r) => isNaN(Number(r))) // Filter out numeric keys (if any)
                  .map((r, i) => {
                    console.log(user?.region);
                    console.log(Region[r as keyof typeof Region]);
                    return (
                      <option
                        selected={
                          user?.region == Region[r as keyof typeof Region]
                        }
                        key={i}
                        value={r}
                      >
                        {r}
                      </option>
                    );
                  })}
              </select>
              <span className="text-danger">
                {errors.region && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col">
            {user?.password ? null : (
              <div className="form-group">
                <label htmlFor="password"> Password*</label>
                <input
                  defaultValue={user?.password}
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  ref={register({ required: true })}
                />
                <span className="text-danger">
                  {errors.password && <span>This field is required</span>}
                </span>
              </div>
            )}
          </div>
          <div className="col">
            {user?.password ? null : (
              <div className="form-group">
                <label htmlFor="comfirmpassword">Comfirm Password*</label>
                <input
                  defaultValue={user?.password}
                  type="password"
                  className="form-control"
                  id="comfirmpassword"
                  name="comfirmpassword"
                  ref={register({ required: true })}
                />
                <span className="text-danger">
                  {errors.comfirmpassword && (
                    <span>This field is required</span>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col ">
            <input
              type="submit"
              value={
                isLoading ? "loading...." : user ? "Edit User" : "Register User"
              }
              style={{ padding: 10 }}
              className=" float-right btn btn-primary"
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </MyContainer>
  );
};

export default AddUser;
